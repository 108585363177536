<template>
    <div>
        <div
            v-if="!settingsStorage.inventoryAdsIsOpen"
            class="promo-light">
            <div class="promo-light-img">
                <img src="/img/promo1.png">
            </div>
            <div class="promo-light-title">
                Inventory Ads
            </div>
            <div class="promo-light-desc">
                Transform your inventory into ads across multiple channels with
                our Ad Library and Ad Launcher
            </div>
            <div class="promo-light-button">
                <button
                    class="promo-link"
                    @click="openPlaybooks('Inventory')">
                    See All Inventory Ads
                </button>
            </div>
            <div class="promo-collapse">
                <span @click="setView(true)">[+] EXPAND THIS AREA</span>
            </div>
        </div>
        <div
            v-else
            class="promo">
            <div class="promo-collapse">
                <span @click="setView(false)">[-] COLLAPSE THIS AREA</span>
            </div>
            <div class="promo-holder">
                <div class="promo-block">
                    <div class="promo-title">
                        Inventory Ads
                    </div>
                    <div class="promo-desc">
                        Transform your inventory into ads across multiple
                        channels with our Ad Library and Ad Launcher.
                    </div>
                    <ul class="promo-list">
                        <li>
                            <icon
                                name="check-circle"
                                color="#7ED321"
                                size="20" />
                            <span>Maximize your inventory exposure</span>
                        </li>
                        <li>
                            <icon
                                name="check-circle"
                                color="#7ED321"
                                size="20" />
                            <span>Automatically find the right buyers</span>
                        </li>
                        <li>
                            <icon
                                name="check-circle"
                                color="#7ED321"
                                size="20" />
                            <span>Dynamically retarget across channels</span>
                        </li>
                        <li>
                            <icon
                                name="check-circle"
                                color="#7ED321"
                                size="20" />
                            <span>Quickly deploy branded content</span>
                        </li>
                    </ul>
                </div>
                <div class="promo-block">
                    <div class="promo-img">
                        <img src="/img/promo1.png">
                    </div>
                    <div class="promo-subtitle">
                        Inventory Ads
                    </div>
                    <div class="promo-subdesc">
                        Let your inventory work for you by leveraging each channel's
                        AI to place the right vehicle in front of the right buyer.
                    </div>
                    <button
                        class="promo-link"
                        @click="openPlaybooks('Inventory')">
                        Launch Inventory Ads
                    </button>
                </div>
                <div class="promo-block">
                    <div class="promo-img">
                        <img src="/img/promo2.png">
                    </div>
                    <div class="promo-subtitle">
                        Model Line Ads
                    </div>
                    <div class="promo-subdesc">
                        Browse and launch OEM-specific ads for each of your top models.
                        We've done the heavy lifting, just click and deploy!
                    </div>
                    <button
                        class="promo-link"
                        @click="openPlaybooks('Model+Line')">
                        Browse Model Line Ads
                    </button>
                </div>
                <div class="promo-block">
                    <div class="promo-img">
                        <img src="/img/promo3.png">
                    </div>
                    <div class="promo-subtitle">
                        Service Ads
                    </div>
                    <div class="promo-subdesc">
                        Supplementing your inventory strategy with fixed ops messaging?
                        Try our OEM-specific service ads!
                    </div>
                    <button
                        class="promo-link"
                        @click="openPlaybooks('Service')">
                        Browse Service Ads
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapGetters } from 'vuex';
import { SET_INVENTORY_ADS_VIEW } from '@/store/mutation-types';

export default {
    components: {
        Icon,
    },
    computed: {
        ...mapGetters([
            'settingsStorage'
        ])
    },
    methods: {
        setView(value) {
            this.$store.commit(SET_INVENTORY_ADS_VIEW, value);
        },
        openPlaybooks(focus) {
            this.$router.push({
                name: 'playbooks-tab',
                query: {
                    focus
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.promo {
    background-color: #FAFBFC;
    padding: 20px 35px 35px 35px;
    border-bottom: 1px solid $alabaster-dark;
    border-top: 1px solid #EAEAEA;
}
.promo-light {
    background-color: #FAFBFC;
    border-bottom: 1px solid $alabaster-dark;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px 0 30px;
    .promo-link {
        margin-top: 0;
    }
    .promo-collapse {
        margin-bottom: 5px;
    }
}
.promo-light-title {
    font-size: 19px;
    font-weight: 600;
    color: $blue-bg;
}
.promo-light-desc {
    width: 30%;
}
.promo-light-img {
    width: 13%;
    overflow: hidden;
    padding-top: 5px;
    img {
        max-width: 100%;
        height: auto;
        vertical-align: top;
        transform: translateY(1px);
    }
}
.promo-holder {
    display: flex;
}
.promo-block {
    width: 25%;
    text-align: center;
}
.promo-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: -10px;
    text-align: left;
}
.promo-desc {
  font-size: 16px;
  text-align: left;
}
.promo-img {
    margin-top: 15px;
    img {
        max-width: 100%;
        height: auto;
    }
}
.promo-subtitle {
    font-size: 20px;
    color: $blue-bg;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 5px;
}
.promo-subdesc {
    font-size: 13px;
    max-width: 80%;
    margin: 0 auto;
}
.promo-link {
    margin-top: 20px;
    color: $white;
    font-weight: 600;
    text-transform: uppercase;
    padding: 2px 12px;
    border-radius: 5px;
    background-color: #7ED321;
}
.promo-collapse {
    text-align: right;
    margin-bottom: 15px;
    span {
        font-size: 9px;
        color: $blue-bg;
        cursor: pointer;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.promo-list {
    margin-top: 45px;
    list-style-type: none;
    padding-left: 10px;
    text-align: left;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        span {
            margin-left: 10px;
            display: inline-block;
        }
    }
}
</style>